.cover {
  flex: 8;
  padding: 32px;
}
.headingRow {
  display: flex;
  font-family: Heebo;
  font-size: 30px;
  font-weight: 900;
  padding-bottom: 16px;
  align-items: baseline;
  justify-content: space-between;
  min-width: 768px;
}
.textred {
  color: #e83424;
}
.blogForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
  // max-width: 400px;
}
.covevUpload {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 5;
}
.coverInput {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  > .headInput {
    flex: 1;
    font-weight: 300;
    font-size: 16px;
    font-family: Kanit;
  }
  > input {
    flex: 5;
    padding: 0.5em 1em;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
  }
  > a {
    width: 30px;
  }
}
textarea {
  flex: 5;
  padding: 0.5em 1em;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  font-size: 14px;
  min-height: 200px;
}
.wrapper {
  max-width: 1440px;
  max-width: 768px;
  margin: auto;
}

.buttonStyle {
  border-radius: 50px;
  padding: 7px 16px;
  color: #e83424;
  border: 1px solid #e83424;
  font-family: Kanit;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  margin: 32px 10px 5px 0;
  cursor: pointer;
  background: #ffffff;
  transition: background 0.2s;
  &:hover {
    background: rgba(232, 52, 36, 0.2);
  }
}

.removeButton {
  display: flex;
  align-items: center;
  color: #e83424;
  width: 30px;
  padding-left: 10px;
}
