.button {
    height: 54px;
    max-height: 54px;
    border-style: none;
    border-radius: 30px;
    width: 100%;
    padding: 15px 100px;
    margin-top: 0.8rem;
    position: relative;
    cursor: pointer;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    background-color: white;
    color: #353332;
    border: 1px solid #a8a8a8;

    &:focus {outline:0;}

    &.red {
        background-color: #e83424;
        color: white;
        border-width: 0px;
    }

    &.facebook {
        background-color: #4268b2;
        color: white;
        border-width: 0px;
    }
}

.disabled {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}

.success {
    background-color: #27ae60 !important;
    cursor: unset;
    // color:  white !important;
}

