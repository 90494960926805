.root {
  display: block;
}

.app {
  background-color: #f3f5f9;
  max-width: 100vw;
}

h1 {
  font-family: Kanit;
  font-style: inherit;
  font-size: 25px;
  font-weight: 700;
}

.wrapper {
  background: linear-gradient(270deg, #c53435 39.13%, rgba(235, 126, 115, 0) 100%);
  width: 100vw;
  height: 100vh;
}

.overlay {
  mix-blend-mode: multiply;
  opacity: 0.5;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100vw;
}

.container {
  height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
    height: 300px;
    border-style: none;
    border-radius: 30px;
    width: 100%;
    padding: 20px 85px;

  .inputBox {
    font-family: Kanit;
    font-style: normal;
    box-sizing: border-box;
    width: 100%;
    color: black;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #a8a8a8;
    padding: 13px 10px;
    margin: 8px 0;
  }
}

.cover {
  flex: 3;
  height: 100vh;
  position: relative;
  display: flex;

  img {
    vertical-align: middle;
    width: 125vh;
    height: 100vh;
  }  
}

.content {
  flex: 2;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  top: -20px;
  padding: 50px 5px 50px;
  margin: 50px;

  img {
    width: auto;
    height: auto;
    position: relative;
    left: -12px;
  }
}