.backGround {
  // background: linear-gradient(92.98deg, #c53435 7.04%, #eb4d3d 90.48%);
  background-color: rgba(220, 175, 165, 0.4);
  flex: 2;
  min-height: 100vh;
  max-width: 500px;
}
.navLink {
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  align-items: flex-start;
  padding: 32px;
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}
.start {
  font-size: 42px;
  font-weight: 600;
  font-family: Heebo;
  color: white;
  display: flex;
  flex-direction: row;
  .pic {
    flex: 1;
  }
}
.profile {
  flex-direction: row;
  color: #7f7f7f;
  font-size: 15px;
  align-items: center;
  display: flex;
  margin: 10px 0 0 -29px;
  .nameRole {
    display: flex;
    flex-direction: column;
    .name {
      padding-left: 30px;
    }
    .role {
      padding-left: 30px;
    }
  }
}
.mid {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5px;
  a {
    padding: 20px 0 0 0;
    text-decoration: none;
    color: #7f7f7f;
    font-family: Heebo;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 3px solid transparent;
    &:hover {
      color: #595959;
      border-bottom: 2px solid rgba($color: rgb(221, 149, 149), $alpha: 1);
      transform: border color;
    }
  }
}
.end {
  padding-top: 32px;
  button {
    background-color: white;
    color: #7f7f7f;
    border: none;
    border-radius: 50px;
    padding: 5px 25px;
    font-size: 16px;
    font-family: Heebo;
    font-weight: 600;
    &:hover {
      background-color: rgba($color: rgb(221, 149, 149), $alpha: 0.5);
      color: white;
    }
  }
}
.container {
  &.normal {
    img {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
  }
  .avatar {
    width: 60px;
    height: 60px;
    background: rgb(221, 149, 149);
    border-radius: 100%;
    font-size: 40px;
    font-weight: 600;
    color: white;
    display: grid;
    place-items: center;
  }
}
