.wrapper {
  background-color: #1f2d3d;
  min-height: 100vh;
}

.headerWrapper {
  > div > div {
    max-width: 100vw;
  }
}

.container {
  // max-width: 100vw;
  // padding: 0 24px;
  // margin: 0 auto;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
}

.navigation {
  min-width: 350px;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #16212e;
  position: fixed;
}

.navigationWrapper {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  height: calc(100% - 30px - 30px);
}

.navList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  > a {
    display: flex;
    align-items: center;
    min-width: 100%;
    padding: 15px 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: rgba($color: #a8a8a8, $alpha: 0.5);
    text-decoration: none;
    > svg {
      height: 18px;
      object-fit: contain;
      margin-right: 8px;
    }
    > svg,
    > svg > path {
      fill: rgba($color: #a8a8a8, $alpha: 0.5);
    }
    &.active {
      color: rgba($color: #a8a8a8, $alpha: 1);
      background-color: #1f2d3d;
      > svg,
      > svg > path {
        fill: rgba($color: #a8a8a8, $alpha: 1);
      }
    }
    &:hover {
      color: rgba($color: #a8a8a8, $alpha: 1);
      > svg,
      > svg > path {
        fill: rgba($color: #a8a8a8, $alpha: 1);
      }
    }
  }
}

.navListHeader {
  padding: 15px 30px;
  margin-bottom: 50px;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 53px;

  color: #a8a8a8;
}

.content {
  margin: 30px 0 0 350px;
  padding: 50px 30px;
  background-color: #1f2d3d;
  flex: 1;
}

.header {
  display: flex;
  align-items: baseline;
  > h1 {
    display: flex;
    align-items: center;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 53px;
  }
  > p {
    margin-left: 12px;
  }
  input {
    border-radius: 50px;
    border: 1px solid #a8a8a8;
    padding: 8px 16px;
    :active {
      outline: none;
    }
  }
}

.refresh {
  margin-left: 15px;

  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  color: #a8a8a8;
  &.disabled {
    pointer-events: none;
  }
}

.table {
  border-collapse: collapse;
  // width: 100%;
  margin-bottom: 30px;
  margin: 0 -10px;
}

.tableHeader {
  > tr {
    vertical-align: middle;
    > th {
      user-select: none;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: black;
      text-align: left;
      padding: 5px 10px;
      &:hover {
        text-decoration: underline;
      }
      &.active {
        // color: rgba($color: #A8A8A8, $alpha: 1);
        color: black;
      }
      > span {
        text-decoration: none !important;
        user-select: none;
        cursor: pointer;
        margin-left: 5px;
        border-radius: 9999px;
        font-weight: normal;
        padding: 0 5px;
        background-color: rgba($color: #a8a8a8, $alpha: 0.1);
      }
    }
  }
}
.tableBody {
  > tr {
    margin-bottom: 5px;
    &:nth-child(odd) {
      background-color: rgba($color: #808997, $alpha: 0.1);
    }
    &.active {
      background-color: rgba($color: #808997, $alpha: 0.1) !important;
    }
    &:hover {
      background-color: rgba($color: #808997, $alpha: 0.05);
    }
    > th {
      vertical-align: middle;
      padding: 2.5px 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: black;
      text-align: left;
      &.cap {
        text-transform: capitalize;
      }
      &.count {
        color: black;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
      &.id {
        color: black;
      }
      &.small {
        white-space: nowrap;
        overflow: hidden;
        max-width: 100px;
      }
      &.more {
        user-select: none;
        > div {
          user-select: none;
          cursor: pointer;
          background-color: rgba($color: #a8a8a8, $alpha: 0.1);
          border-radius: 9999px;
          padding: 5px 10px;
        }
      }
    }
  }
}

.contentWrapper {
  position: relative;
  
  .searchInput {
    border-radius: 50px;
    border: 1px solid #a8a8a8;
    padding: 8px 16px;
    margin-bottom: 12px;
    :active {
      outline: none;
    }
  }
}

.btnActionList {
  display: flex;
  margin-top: 16px;
}

.selectedItem {
  // overflow-y: scroll;
  position: relative;
  bottom: 0;
  right: 0;
  background-color: #e5e5e5;
  position: fixed;
  width: calc(100vw - 350px);
  transition: 0.25s ease;
  visibility: hidden;
  opacity: 0;
  transform: translate(0, 100%);
  &.active {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
  }
}

.selectedItemWrapper {
  padding: 50px;
  overflow-y: scroll;
  max-height: 250px;
  //padding: 20px 0 0 0;
  > h1 {
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 53px;
    color: #1f2d3d;
    margin: 0 0 10px 0;
  }
}

.closeBtn {
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 20px;
}

.detailRow {
  display: flex;
  > * {
    margin-right: 10px;
  }
}

.detailItem {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1f2d3d;
}

.detailBtnRow {
  display: flex;
  margin: 15px 0;
}

.deleteBtn {
  cursor: pointer;
  user-select: none;
  background: #f76116;
  border-radius: 9999px;

  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;

  padding: 5px 15px;

  color: #a8a8a8;

  &:hover {
    background: #ff7a00;
  }
}

.switchBtn {
  cursor: pointer;
  user-select: none;
  border-radius: 9999px;

  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;

  padding: 5px 5px 5px 15px;
  margin-right: 10px;

  color: #a8a8a8;
  background: #808997;
  > span {
    color: #808997;
    background-color: #a8a8a8;
    border-radius: 9999px;
    padding: 0 10px;
    font-weight: normal;
  }

  &.active {
    background: #173c6d;
    > span {
      color: #173c6d;
    }
  }

  &:hover {
    background: #3e51ad;
    > span {
      color: #3e51ad;
    }
  }
}

.prompModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #1f2d3d, $alpha: 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    > * {
      text-align: center;
    }
    > h2 {
      color: #a8a8a8;
      font-family: Heebo;
      font-style: normal;
      font-weight: bold;
      > span {
        margin: 0 10px;
        padding: 0 5px;
        border-bottom: 1px solid #a8a8a8;
      }
    }
    > p {
      color: #a8a8a8;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.deletingPromptBtnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    margin: 0 20px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:first-child {
      background-color: #a8a8a8;
      border-radius: 5px;
      color: #1f2d3d;
      padding: 10px 30px;
    }
    &:last-child {
      color: #a8a8a8;
    }
  }
}

.deletingWarning {
  margin-top: 50px;
  text-align: center;
  color: #a8a8a8;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}
.codeBlockHeader {
  margin-top: 10px;
}
.codeBlock {
  margin-top: 5px;
  background-color: #eeeeee;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 24px;
  > pre {
    margin: 0;
    display: flex;
    flex-direction: column;
  }
}

///////

.cellImage {
  width: 150px;
  height: 100px;
  background: #646464 no-repeat scroll 0 0;
}

.inlineImage {
  width: 10em;
}

.cellWidth {
  max-width: 250px;
  text-align: left;
}

.addNewBlog {
  font-weight: 300;
  border-radius: 50px;
  padding: 7px 16px;
  color: #e83424;
  border: 1px solid #e83424;
  font-family: Kanit;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  margin: 5px 10px 5px 0;
  cursor: pointer;
  background: #ffffff;
  transition: background 0.2s;
  &:hover {
    background: rgba(232, 52, 36, 0.2);
    color: #e83424;
  }
}

.btnList {
  display: block;
  padding: 18px;
}
.coverLayout {
  display: flex;
  flex-direction: column;
  flex: 8;
  padding: 32px;
  min-width: 1024px;
}
.bg {
  background-color: rgba(220, 175, 165, 0.4);
}
.coverInput {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  > .headInput {
    flex: 1;
    font-weight: 300;
    font-size: 16px;
    font-family: Kanit;
  }
  > input {
    flex: 5;
    padding: 0.5em 1em;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
  }
}
