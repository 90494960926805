@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Heebo;
  src: url('./assets/fonts/Heebo-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Heebo;
  src: url('./assets/fonts/Heebo-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Heebo;
  src: url('./assets/fonts/Heebo-Light.ttf');
  font-weight: lighter;
}

@font-face {
  font-family: Noto Sans;
  src: url('./assets/fonts/NotoSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Noto Sans;
  src: url('./assets/fonts/NotoSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Kanit;
  src: url('./assets/fonts/Kanit-Regular.ttf');
  font-weight: normal;
}
@font-face {
  font-family: Kanit Thin;
  src: url('./assets/fonts/Kanit-ExtraLight.ttf');
  font-weight: normal;
}
body {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
button {
  font-family: 'Kanit';
}
