.spinnerWrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    background: white;
    opacity: 0.5;
    display: grid;
    place-items: center;

    .spinner {
        z-index: 99999;
    }
}

.layoutAdmin {
    display: flex;
    flex-direction: row;
}

.coverLayout {
    display: flex;
    flex-direction: column;
    flex: 8;
    padding: 32px;
}
